import * as env from "./env"
import { handleApiResponse } from "./api/response"

//
// Temporarily import APIs from src/api/
//
import {
  auth0Provider as gAuth0Provider,
  setAuth0Provider as setAuth0ProviderRefactored,
  getHeaders as getHeadersRefactored,
  wrappedFetch as wrappedFetchRefactored,
} from "../api/apiHelpers"
import { api as refactoredApis } from "../api/api"
import { isSuccessStatusCode, UI_METRICS_DATABASE } from "./common"

let gCachedSecrets = null //globalCachedSecrets

// Export moved functions
export const setAuth0Provider = setAuth0ProviderRefactored
export const getHeaders = getHeadersRefactored
export const wrappedFetch = wrappedFetchRefactored

// Export new APIs
export const updateShelves = refactoredApis.updateShelves
export const getStore = refactoredApis.getStore
export const getStoreMaxLaneNoiseThreshold = refactoredApis.getStoreMaxLaneNoiseThreshold
export const getStoreLayout = refactoredApis.getStoreLayout
export const upsertStoreLayout = refactoredApis.upsertStoreLayout

//
// Original APIs
//

// use api enpoint env vars
let api = env.getDashboardServerAPI()
export const zippinApi = env.getZippinPlatformAPI()
let operator_api = env.getOperatorBackendAPI()
let operator_ui_url = env.getOperatorUIURL()
const TENANT_BACKEND_API_BASE_URL = env.getAppBackendBaseUrl()
let z_api = env.getZAPI()
const storeConfigApi = env.getStoreConfigAPIBaseUrl()
let taskAssignerApi = env.getTaskAssignerAPI()
let taskAssignmentRuleApi = env.getTaskAssignmentRuleAPI()
let taskResultIngestorApi = env.getTaskResultIngestorAPI()

// const SUCCESS_RESPONSE_CODE = 200

// throw errors if api enpoint env vars are not set
if (!api) {
  console.error(new Error("Dashboard API endpoint is not set."))
}
if (!zippinApi) {
  console.error(new Error("Zippin Platform API endpoint is not set."))
}
if (!operator_api) {
  console.error(new Error("Operator API endpoint is not set."))
}
if (!operator_ui_url) {
  console.error(new Error("Operator UI endpoint is not set."))
}
if (!z_api) {
  console.error(new Error("Z API endpoint is not set."))
}

export const OPERATOR_API_URL = operator_api
export const OPERATOR_UI_URL = operator_ui_url
export const Z_API_URL = z_api

/**
 * @returns {string} user's email
 */
export const getUserEmail = () => {
  if (gAuth0Provider) {
    return gAuth0Provider.user.email
  } else {
    throw new Error("setAuth0Provider must be set before calling getUserEmail().")
  }
}

/**
 * @returns {string} user's name
 */
export const getUserName = () => {
  if (gAuth0Provider) {
    return gAuth0Provider.user.name
  } else {
    throw new Error("setAuth0Provider must be set before calling getUserName().")
  }
}

export function getSummary(testUsers) {
  console.log("testUsers = " + testUsers)
  let val = 0
  if (testUsers) {
    val = 1
  }
  return wrappedFetch(`${api}/summary?testUsers=${val}`, {
    method: "GET",
  }).then((resp) => resp.json())
}

export function getMTOrdersBatch(storeId, orderIds) {
  let values = {}
  values.orderIds = orderIds
  values.storeId = storeId
  return wrappedFetch(`${api}/mtOrdersBatch`, {
    method: "POST",
    body: JSON.stringify(values),
  }).then((resp) => resp.json())
}

export function getShelfInventory(storeId, searchText, page, limit, isExport) {
  let fieldName = ""
  let searchValue = ""
  if (searchText) {
    if (searchText.includes(":")) {
      let splitString = searchText.split(":")
      fieldName = splitString[0].toLowerCase()
      searchText = splitString[1].toLowerCase()
    } else {
      searchText.toLowerCase()
    }
    searchText.toLowerCase()
    searchValue = encodeURIComponent(searchText)
  }
  let offset = null
  if (page != null) {
    offset = page * limit
  }
  const apiEndPoint = `${OPERATOR_API_URL}/stores/${storeId}/shelves-products-details?field=${fieldName}&search=${searchValue}&limit=${limit}&offset=${offset}&export=${isExport}`

  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => resp.json())
}

export function getShelvesConfig(storeId) {
  let apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/shelves-cfg-details"
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => resp.json())
}

export function getOrders(storeId, start, count, cartFilters, startDate, endDate) {
  const URLQueryParamsObject = {
    start,
    count,
    ...cartFilters,
    ...(startDate && { startDate }),
    ...(endDate && { endDate }),
  }
  const URLQueryParams = new URLSearchParams(URLQueryParamsObject)
  const apiEndPoint1 = `${OPERATOR_API_URL}/stores/${storeId}/orders-details?${URLQueryParams.toString()}`
  return wrappedFetch(`${apiEndPoint1}`, {
    method: "GET",
  }).then((resp) => resp.json())
}

export function getEventsByTime(storeId, startTime = 0, endTime, offset = 0, count = 100) {
  let apiEndPoint = `${OPERATOR_API_URL}/stores/${storeId}/events-by-time?startTime=${startTime}&endTime=${endTime}&offset=${offset}&count=${count}`
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then(function (resp) {
    if (!resp.ok) {
      return { error: true, message: resp.statusText }
    }
    return resp.json()
  })
}

export function getCameras(storeId) {
  try {
    return wrappedFetch(`${storeConfigApi}/stores/devices/cameras?storeId=${storeId}`, {
      method: "GET",
    }).then((response) => response.json())
  } catch (e) {
    console.error("getCameras error: ", e)
  }
}

export function getProducts(storeId) {
  let apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/products-details"
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => resp.json())
}

export function getProduct(storeId, id) {
  const apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/products/" + id
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => resp.json())
}

export function getShelvesDetails(storeId) {
  let apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/shelves-details"
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => resp.json())
}

/**
 * @param {string} storeId
 * @returns total pendingCarts of a store
 */
export function getPendingCarts(storeId) {
  let apiEndPoint = Z_API_URL + "/stores/" + storeId + "/verified-pending-carts"
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => {
    if (resp.status !== 200) {
      return { data: null, store: storeId, status: resp.status }
    } else {
      console.log("api.js::getPEndingCarts storeID & response", storeId, resp)
      return resp.json().then((data) => {
        return { data: data, store: storeId, status: resp.status }
      })
    }
  })
}

/**
 * @param {string} requestId
 * @param {string} type request type (pending,success,failed)
 * @returns total import Progress of a requestId
 */
export async function getSkuImportProgressByStatus(requestId, type) {
  let apiEndPoint = `${Z_API_URL}/skus/import-request/${requestId}/${type}`
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => {
    if (isSuccessStatusCode(resp.status)) {
      console.log("api.js::getSkuImportProgressByStatus requestId & response", requestId, resp)
      return resp.json()
    } else {
      return { requestId: requestId, status: resp.status }
    }
  })
}

/**
 * @param {string} requestId
 * @returns the status of cancel request of requestId
 */
export async function cancelSkuImportRequest(requestId) {
  let apiEndPoint = `${Z_API_URL}/skus/import-request/${requestId}`
  return wrappedFetch(`${apiEndPoint}`, {
    method: "DELETE",
  }).then((resp) => {
    console.log("api.js::cancelSkuImportRequest requestId & response", requestId, resp)
    return resp.json()
  })
}

/**
 * @param {string} storeId
 * @param {boolean} withShelfDetails optionally true to get lane's shelf details
 * @returns response
 */
export function getLaneDetails(storeId, withShelfDetails) {
  let apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/lanes"
  if (withShelfDetails) {
    apiEndPoint = apiEndPoint + "?withShelfDetails=true"
  }
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => resp.json())
}

export async function zeroAllLanes(storeId) {
  let apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/lanes/zeroAll"
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "PUT",
  })
  return resp.json()
}

export function updateShelfOld(storeId, shelfId, shelfData) {
  let apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/shelves/" + shelfId
  console.log("api.updateShelfOld(storeId, shelfId, shelfData)", storeId, shelfId, shelfData)
  return wrappedFetch(`${apiEndPoint}`, {
    method: "PUT",
    body: JSON.stringify(shelfData),
  }).then((resp) => resp.json())
}

export function calibrateShelf(storeId, shelfId, qty, target) {
  let apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/shelves/" + shelfId + "/calibrate"
  let values = {}
  values.quantity = qty
  values.target = target
  console.log("values = " + JSON.stringify(values))
  return wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify(values),
  }).then((resp) => resp.json())
}

/**
 * @param {string} storeId
 * @param {string} laneId
 * @param {number} qty
 * @param {string} target "tare" or "scale"
 * @returns
 */
export function calibrateLane(storeId, laneId, qty, target) {
  const apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/lanes/" + laneId + "/calibrate"
  const values = {}
  values.quantity = qty
  values.target = target
  console.log("values = " + JSON.stringify(values))
  return wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify(values),
  }).then((resp) => resp.json())
}

export function updateStorePriority(storeId, storePriority) {
  const values = {}
  values.storeId = storeId
  values.storePriority = storePriority
  let apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/priority"
  return wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify(values),
  }).then((response) => response.json())
}

export function getStoresAndPriority() {
  let apiEndPoint = OPERATOR_API_URL + "/stores/priorities"
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => resp.json())
}
export function getAllTasks(status) {
  let apiEndPoint = `${taskAssignerApi}/hitl-monitoring-api/tasks?status=${status}`
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => resp.json())
}

export function getDifficultTasks() {
  let apiEndPoint = taskAssignerApi + "/difficult-tasks"
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => resp.json())
}

export function getExpertOperators() {
  let apiEndPoint = taskAssignerApi + "/expert-operators"
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => resp.json())
}

export function addExpertOperators(operators) {
  const values = {}
  values.operators = operators
  let apiEndPoint = taskAssignerApi + "/expert-operators"
  return wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify(values),
  }).then((resp) => {
    return resp.json()
  })
}

export function deleteExpertOperator(operator) {
  let apiEndPoint = taskAssignerApi + "/delete-expert-operator"
  return wrappedFetch(`${apiEndPoint}`, {
    method: "DELETE",
    body: JSON.stringify({ operator: operator }),
  }).then((resp) => {
    return resp.json()
  })
}

export function getHITLAdmins() {
  try {
    const apiEndPoint = `${storeConfigApi}/stores/user-role-mapping?roleName=hitl_admin`
    return wrappedFetch(`${apiEndPoint}`, {
      method: "GET",
    }).then((resp) => resp.json())
  } catch (err) {
    console.error(`getHITLAdmins: Error in checking getting hitl_admin users `, err)
    return null
  }
}

export function checkIfHITLAdmin(userId) {
  try {
    const apiEndPoint = `${storeConfigApi}/stores/user-role-mapping?userId=${userId}&roleName=hitl_admin`
    return wrappedFetch(`${apiEndPoint}`, {
      method: "GET",
    }).then((resp) => resp.json())
  } catch (err) {
    console.error(`checkIfHITLAdmin: Error in checking if ${userId} is hitl_admin: `, err)
    return null
  }
}

export async function addHITLAdmin(userId, updatedBy) {
  try {
    const response = await checkIfHITLAdmin(updatedBy)
    const isAdmin = response && response.length > 0 ? true : false
    if (isAdmin) {
      const values = { userId, roleName: "hitl_admin", scope: { stores: ".*" }, updatedBy }
      const apiEndPoint = `${storeConfigApi}/stores/user-role-mapping`
      return wrappedFetch(`${apiEndPoint}`, {
        method: "POST",
        body: JSON.stringify(values),
      }).then((resp) => resp.json())
    } else {
      console.error(`addHITLAdmin: Operator doesn't have enough privilege to add HITL Admin`)
    }
  } catch (err) {
    console.error(`addHITLAdmin: Error while adding ${userId} as hitl_admin: `, err)
    return null
  }
}

export async function deleteHITLAdmin(userId, updatedBy) {
  try {
    const response = await checkIfHITLAdmin(updatedBy)
    const isAdmin = response && response.length > 0 ? true : false
    if (isAdmin) {
      const apiEndPoint = `${storeConfigApi}/stores/user-role-mapping?userId=${userId}&roleName=hitl_admin`
      return wrappedFetch(`${apiEndPoint}`, {
        method: "DELETE",
      }).then((resp) => resp.json())
    } else {
      console.error(`deleteHITLAdmin: Operator doesn't have enough privilege to delete HITL Admin`)
    }
  } catch (err) {
    console.error(`deleteHITLAdmin: Error while deleting ${userId}: `, err)
    return null
  }
}

export function getPausedStores() {
  let apiEndPoint = taskAssignerApi + "/paused-stores"
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => resp.json())
}

export function pauseStore(storeId) {
  let apiEndPoint = taskAssignerApi + "/stores/" + storeId + "/pause-tasks"
  return wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
  }).then((resp) => {
    return resp.json().then((data) => {
      return { data: data, status: resp.status }
    })
  })
}

export function resumeStore(storeId) {
  let apiEndPoint = taskAssignerApi + "/stores/" + storeId + "/resume-tasks"
  return wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
  }).then((resp) => {
    return resp.json().then((data) => {
      return { data: data, status: resp.status }
    })
  })
}

/**
 * Set epoch endtime
 */

const epochEndtime = 86400000 - 1

export async function getRepeatShoppers(stores, startDate, endDate, isExport) {
  console.log("@Repeat shoppers api start and end time:", stores, startDate, endDate)
  let apiEndPoint =
    Z_API_URL +
    `/carts/repeatShoppers?startTime=${startDate}&endTime=${endDate}&stores=${stores}&isExport=${isExport}`
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  return await resp.json()
}

export async function getSalesSummaryV2(stores, startDate, endDate, exportType) {
  console.log("@Summary api start and end time:", stores, startDate, endDate)
  let apiEndPoint =
    Z_API_URL +
    `/carts/summary?startTime=${startDate}&endTime=${endDate}&stores=${stores}&exportType=${exportType}`
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  let response = await resp.json()
  return [response, resp.status]
}
export async function getSalesDataV2(stores, startDate, endDate, offset, limit, csvExport) {
  console.log("@Details api start and end time:", stores, startDate, endDate)
  let apiEndPoint
  if (offset === null && limit === null) {
    apiEndPoint =
      Z_API_URL +
      `/carts?startTime=${startDate}&endTime=${endDate}&stores=${stores}&csvExport=${csvExport}`
  } else {
    apiEndPoint =
      Z_API_URL +
      `/carts?startTime=${startDate}&endTime=${endDate}&stores=${stores}&limit=${limit}&offset=${offset}&csvExport=${csvExport}`
  }
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  let response = await resp.json()
  return [response, resp.status]
}

export const querySkuCategories = async (tenantId, query) => {
  let apiEndPoint = `${zippinApi}/tenant/${tenantId}/skuCategories/query`
  console.log("querySkuCategories apiEndPoint = " + apiEndPoint)
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify({ query }),
  })
  return resp.json()
}

export const querySkuTags = async (tenantId) => {
  let apiEndPoint = `${zippinApi}/tenant/${tenantId}/skuTags`
  console.log("querySkuTags apiEndPoint = " + apiEndPoint)
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  return resp.json().then((data) => ({ status: resp.status, body: data }))
}
/**
 * Method to fetch paginated Skus based on tenantId, storeId and query param
 * @param {string} tenantId - The id representing tenant
 * @param {string} query - search keyword to filter Sku
 * @param {string} storeId - The id representing store
 * @param {number} limit - number of records to fetch
 * @param {number} page - offset to fetch Skus
 * @returns paginated Skus
 */
export const querySkus = async (
  tenantId,
  query,
  storeId,
  limit,
  page,
  archived,
  isSkuExport,
  orderBy,
  order,
  selectedView
) => {
  let offset = null
  if (page != null) {
    offset = page * limit
  }
  let apiEndPoint = `${zippinApi}/tenant/${tenantId}/skus/query?limit=${limit}&offset=${offset}&archived=${archived}&isSkuExport=${isSkuExport}&selectedView=${selectedView}`
  if (orderBy !== null && order !== null) {
    apiEndPoint += `&orderBy=${orderBy}&order=${order}`
  }

  console.log("querySkus apiEndPoint = " + apiEndPoint)
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify({ query, storeId }),
  })
  return resp.json()
}

/**
 * Method to fetch the tax types for the given tenant
 * @param {string} tenantId - The id representing tenant
 * @returns tax types for the tenant
 */
export const getTaxTypesByTenantId = async (tenantId) => {
  let apiEndPoint = `${zippinApi}/tenant/${tenantId}/taxTypes`
  console.log("getTaxTypesByTenantId apiEndPoint = " + apiEndPoint)
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  return [await resp.json(), resp.status]
}

/**
 * Method to fetch the pricingScheme for the given store
 * @param {string} storeId - The id representing store
 * @returns key config result for the store
 */
export const getStoreConfigByStoreId = async (storeId, keys) => {
  let apiEndPoint = `${zippinApi}/stores/${storeId}/storeConfig`
  console.log("getStoreConfigByStoreId apiEndPoint = " + apiEndPoint)
  let response = await wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify({ keys }),
  })
  console.log("response", response)
  return [await response.json(), response.status]
}

export const queryStoreSkus = async (storeId, query) => {
  let apiEndPoint = `${zippinApi}/stores/${storeId}/skus/query`
  console.log("queryStoreSkus apiEndPoint = " + apiEndPoint)
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify({ query }),
  })
  return resp.json()
}

export const queryStoreShelves = async (storeId, query) => {
  let apiEndPoint = `${zippinApi}/stores/${storeId}/shelves/search`
  console.log("queryStoreShelves apiEndPoint = " + apiEndPoint)
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify(query),
  })
  return resp.json()
}

export const addSku = async (tenantId, data) => {
  let apiEndPoint = `${zippinApi}/tenant/${tenantId}/skus`
  console.log("addSku apiEndPoint = " + apiEndPoint)
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify(data),
  })
  return handleApiResponse(resp)
}

/**
 * pricing profiles
 */

export const getPricingProfiles = async (tenantId) => {
  let apiEndPoint = `${Z_API_URL}/tenant/${tenantId}/skus/pricing-profiles`
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  return resp.json()
}

export const getPricingProfile = async (tenantId, profile) => {
  let apiEndPoint = `${Z_API_URL}/tenant/${tenantId}/skus/pricing-profiles/${profile.name}`
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  return resp.json()
}

export const activatePricingProfile = async (tenantId, storeId, profile) => {
  let apiEndPoint = `${Z_API_URL}/tenant/${tenantId}/skus/pricing-profiles/${profile.name}`
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "PATCH",
    body: JSON.stringify({ storeId }),
  })
  if (!resp.ok) {
    throw new Error(resp.data)
  }
  return resp.json()
}

export const deletePricingProfile = async (tenantId, profileId) => {
  let apiEndPoint = `${Z_API_URL}/tenant/${tenantId}/skus/pricing-profiles/${profileId}`
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "DELETE",
  })
  return resp.json()
}

export const addPricingProfile = async (tenantId, storeId, profile, data) => {
  let apiEndPoint = `${Z_API_URL}/tenant/${tenantId}/skus/pricing-profiles`
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify({ profile, storeId, data }),
  })
  return resp.json()
}

/**
 * bulk add or update skus.
 */
export const importSku = async (tenantId, data, storeId) => {
  let apiEndPoint = `${Z_API_URL}/tenant/${tenantId}/skus/import`
  console.log("importSku apiEndPoint = " + apiEndPoint)
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify({ data: data, storeId: storeId }),
  })
  return resp.json()
}

/**
 * Gets all the pending import sku request for the given store's tenant.
 */
export const getAllImportSkuPendingRequestByTenant = async (storeId) => {
  let apiEndPoint = `${OPERATOR_API_URL}/stores/${storeId}/skus/pending-imports`
  let response = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  return response.json()
}

export const updateSku = async (tenantId, skuId, sku) => {
  let apiEndPoint = `${zippinApi}/tenant/${tenantId}/skus/${skuId}`
  console.log("updateSku apiEndPoint = " + apiEndPoint)
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "PUT",
    body: JSON.stringify(sku),
  })
  return handleApiResponse(resp)
}

export async function deleteSku(tenantId, skuId) {
  let apiEndPoint = `${zippinApi}/tenant/${tenantId}/skus/${skuId}`
  console.log("deleteSku apiEndPoint = " + apiEndPoint)
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "DELETE",
  })
  return handleApiResponse(resp)
}

export async function setDefaultSku(storeId, shelfId, skuId) {
  let apiEndPoint = `${zippinApi}/stores/${storeId}/shelves`
  console.log("setDefaultSku apiEndPoint = " + apiEndPoint)
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "PUT",
    body: JSON.stringify([{ shelfId: shelfId, sku: skuId }]),
  })
  return resp.json()
}

export async function getTenantConfig(tenantId, configKey) {
  let response = await wrappedFetch(`${api}/tenant/${tenantId}/tenantConfig/${configKey}`, {
    method: "GET",
  })
  return response.json()
}

/**
 * @param {string} tenantId
 * @param {Array<string>} configKeys config keys to get values for
 * @returns
 */
export async function getTenantConfigById(tenantId, configKeys) {
  let response = await wrappedFetch(`${zippinApi}/tenants/${tenantId}/config`, {
    method: "POST",
    body: JSON.stringify({
      keys: configKeys,
    }),
  })
  return response.json()
}

export async function getRecentActivityLog(storeId, activityType) {
  let response = await wrappedFetch(
    `${zippinApi}/stores/${storeId}/activityLog/${activityType}/recent`,
    {
      method: "GET",
    }
  )
  return response.json()
}

export async function updateRampPricing(
  tenantId,
  storeId,
  skuIds,
  direction,
  rampAmount,
  isMultipleStoreAllowed
) {
  let apiEndPoint = `${zippinApi}/tenant/${tenantId}/skus/ramp`
  console.log("updateRampPricing apiEndPoint = " + apiEndPoint)
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "PUT",
    body: JSON.stringify({
      storeId: storeId,
      skuIds: skuIds,
      direction: direction,
      rampAmount: rampAmount,
      isMultipleStoreAllowed: isMultipleStoreAllowed,
    }),
  })
  return resp.json().then((data) => ({ status: resp.status, body: data }))
}

/**
 * Get user role within each of the stores.
 */
export function getUserStoreAccess() {
  let apiEndPoint = api + "/user_store_access"
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => {
    if (!resp.ok) {
      return { error: true, message: resp.statusText }
    }
    return resp.json()
  })
}

/**
 * Gets all the stores where the user has the access given by `userRole`.
 */
export async function getStoresForUserRole(userRole) {
  const storesToUserAccess = await getUserStoreAccess()
  const stores = storesToUserAccess.data
    .filter((storeDetails) => storeDetails.user_role === userRole)
    .map((storeDetails) => storeDetails.zippin_store_id)
  return stores
}

/**
 * Get dashboard user role for a user of the stores.
 */
export async function getDashboardUserAccessByEmail(storeId, email) {
  const apiEndPoint = `${storeConfigApi}/stores/${storeId}/userAccess/${email}`
  const resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  if (!resp.ok) {
    return { error: true, message: resp.statusText }
  }
  return resp.json()
}

export async function getTenantByStoreId(storeId) {
  let response = await wrappedFetch(`${api}/store/${storeId}/tenant`, {
    method: "GET",
  })
  return response.json()
}

export function payOrder(storeId, order, items) {
  let values = {}
  values.customerId = order.customer_id
  let orderId = order.order_id
  let trimmedOrderId = orderId.replace("order-v2::", "")
  values.orderId = trimmedOrderId
  if (order.start) values.entryTimestamp = Number(order.start)
  if (order.end) values.exitTimestamp = Number(order.end)

  console.log("payOrder")
  console.log(order.end)
  console.log(new Date(order.end))
  console.log(new Date(Number(order.end)))

  values.items = []
  for (var i = 0; i < items.length; i++) {
    let item = {}
    let productId = items[i].key
    productId = productId.replace(/^prod::/, "")
    if (
      storeId === "fremont215" ||
      storeId === "nom71" ||
      storeId === "testlab1" ||
      storeId === "kings1"
    ) {
      item.parent = productId
    } else {
      item.sku = productId
    }
    item.quantity = items[i].qty
    values.items.push(item)
  }
  values.storeId = storeId
  values.cartType = order.role
  console.log("values = " + JSON.stringify(values))

  let apiEndPoint = api + "/pay_order"
  return wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify(values),
  }).then((resp) => resp.json())
}

export function getOrder(storeId, orderId) {
  let apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/order/" + orderId
  console.log("apiEndPoint = " + apiEndPoint)
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => {
    if (!resp.ok) {
      return { error: true, message: resp.statusText }
    }
    return resp.json()
  })
}

export function getOrdersByWildCardSearch(storeId, orderId, start, count) {
  let apiEndPoint =
    OPERATOR_API_URL +
    "/stores/" +
    storeId +
    "/orders-search/" +
    orderId +
    "?start=" +
    start +
    "&count=" +
    count
  console.log("apiEndPoint = " + apiEndPoint)
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => {
    if (!resp.ok) {
      return { error: true, message: resp.statusText }
    }
    return resp.json()
  })
}

export function setOrder(storeId, orderId, values) {
  let apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/orders/" + orderId
  console.log("apiEndPoint = " + apiEndPoint)
  return wrappedFetch(`${apiEndPoint}`, {
    method: "PUT",
    body: JSON.stringify(values),
  }).then((resp) => {
    if (!resp.ok) {
      return { error: true, message: resp.statusText, code: resp.status }
    }
    return resp.json()
  })
}

export function editOrder(storeId, orderId, values) {
  let apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/orders/" + orderId + "/edit"
  console.log("apiEndPoint = " + apiEndPoint)
  return wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify(values),
  }).then((resp) => {
    if (!resp.ok) {
      return { error: true, message: resp.statusText }
    }
    return resp.json()
  })
}

export function forceExit(storeId, orderId) {
  let apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/orders/" + orderId + "/force-exit"
  console.log("apiEndPoint = " + apiEndPoint)
  return wrappedFetch(`${apiEndPoint}`, {
    method: "PUT",
  }).then((resp) => resp.json())
}

export function getEvents(storeId, start, count) {
  let apiEndPoint =
    OPERATOR_API_URL + "/stores/" + storeId + "/events-details?start=" + start + "&count=" + count
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => resp.json())
}

export async function queryEvents(storeId, dataQuery) {
  let apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/events-query"
  let response = await wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify(dataQuery),
  })
  return response.json()
}

export async function queryOrders(storeId, dataQuery) {
  let apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/orders-query"
  let response = await wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify(dataQuery),
  })
  return response.json()
}

export function getEvent(storeId, eventId) {
  let apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/events/" + eventId
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then((resp) => resp.json())
}

export function getEventsDuringOrder(storeId, orderId) {
  let apiEndPoint = OPERATOR_API_URL + "/stores/" + storeId + "/events-order/" + orderId
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then(function (resp) {
    if (!resp.ok) {
      return { error: true, message: resp.statusText }
    }
    return resp.json()
  })
}

export function getOrdersByTime(
  storeId,
  startTime = 0,
  endTime,
  cartFilters,
  offset = 0,
  count = 100
) {
  const URLQueryParams = new URLSearchParams({
    startTime,
    endTime,
    offset,
    count,
    ...cartFilters,
  })
  const apiEndPoint = `${OPERATOR_API_URL}/stores/${storeId}/orders-by-time?${URLQueryParams.toString()}`
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then(function (resp) {
    if (!resp.ok) {
      return { error: true, message: resp.statusText }
    }
    return resp.json()
  })
}

export function getOrderTimeline(storeId, orderId) {
  const apiEndPoint = `${OPERATOR_API_URL}/stores/${storeId}/order/${orderId}/timeline`
  return wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  }).then(function (resp) {
    if (!resp.ok) {
      throw new Error(resp.json())
    }
    return resp.json()
  })
}

export function getMTCustomers(storeId, sortBy, sortDirection, offset, count) {
  return wrappedFetch(
    `${api}/customers?zippinStoreId=${storeId}&pivot=${sortBy}&sortDirection=${sortDirection}&offset=${offset}&count=${count}`,
    {
      method: "GET",
    }
  ).then((resp) => resp.json())
}

export function getCustomer(customerId) {
  return wrappedFetch(`${api}/customer?id=${customerId}`, {
    method: "GET",
  }).then((resp) => resp.json())
}

export function searchMTCustomers(storeId, email) {
  return wrappedFetch(`${api}/search_customers?zippinStoreId=${storeId}&email=${email}`, {
    method: "GET",
  }).then((resp) => resp.json())
}

export function deleteCustomer(id) {
  let values = { id: id }
  return wrappedFetch(`${api}/customer/delete`, {
    method: "POST",
    body: JSON.stringify(values),
  }).then((resp) => resp.json())
}

export function blockCustomer(id) {
  let values = { id: id }
  return wrappedFetch(`${api}/customer/block`, {
    method: "POST",
    body: JSON.stringify(values),
  }).then((resp) => resp.json())
}

export function getOrderHistory(id) {
  return wrappedFetch(`${api}/customer/order_history?id=${id}`, {
    method: "GET",
  }).then((resp) => resp.json())
}

export function getMTOrders(storeId, offset, count, filter) {
  return wrappedFetch(
    `${api}/orders?zippinStoreId=${storeId}&offset=${offset}&count=${count}&${filter}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((resp) => resp.json())
}

export function getFailedOrders() {
  return wrappedFetch(`${api}/failed_orders`, {
    method: "GET",
  }).then((resp) => resp.json())
}

export function searchMTOrders(storeId, query, label) {
  return wrappedFetch(
    `${api}/search_orders?zippinStoreId=${storeId}&query=${query}&label=${label}`,
    {
      method: "GET",
    }
  ).then((resp) => resp.json())
}

export async function operatorDashboard(isExport, dataQuery) {
  dataQuery["isExport"] = isExport
  let apiEndPoint = OPERATOR_API_URL + "/operator-dashboard"
  let response = await wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify(dataQuery),
  })
  return response.json()
}

export async function getOperators() {
  let apiEndPoint = `${OPERATOR_API_URL}/operators`
  let response = await wrappedFetch(apiEndPoint, {
    method: "GET",
  })
  const operators = await response.json()
  return operators
}

export async function shouldShowStripeConnect(storeId) {
  let apiEndPoint = `${api}/merchant/${storeId}/show_stripe_connect`
  let response = await wrappedFetch(apiEndPoint, {
    method: "GET",
  })
  const resultJson = await response.json()
  return resultJson
}

export async function linkStripeAccount(storeId, code) {
  let apiEndPoint = `${api}/merchant/${storeId}/link_stripe_account`
  let response = await wrappedFetch(apiEndPoint, {
    method: "POST",
    body: JSON.stringify({ code: code }),
  })
  const resultJson = await response.json()
  return resultJson
}

export async function getConnectedAccount(storeId) {
  let apiEndPoint = `${api}/merchant/${storeId}/connected_account`
  let response = await wrappedFetch(apiEndPoint, {
    method: "GET",
  })
  const resultJson = await response.json()
  return resultJson
}

export async function createConnectedAccount(storeId, values) {
  let apiEndPoint = `${api}/merchant/${storeId}/connected_account/create`
  let response = await wrappedFetch(apiEndPoint, {
    method: "POST",
    body: JSON.stringify(values),
  })
  const resultJson = await response.json()
  return resultJson
}

export async function updateConnectedAccount(storeId, values) {
  let apiEndPoint = `${api}/merchant/${storeId}/connected_account/update`
  let response = await wrappedFetch(apiEndPoint, {
    method: "POST",
    body: JSON.stringify(values),
  })
  const resultJson = await response.json()
  return resultJson
}

export async function checkIsZippinStore(storeId) {
  let apiEndPoint = `${api}/store/${storeId}/checkIsZippinStore`
  let response = await wrappedFetch(apiEndPoint, {
    method: "GET",
  })
  const resultJson = await response.json()
  return resultJson
}

/**
 * Gets the receipt by cartId and storeId
 */
export async function getReceipt(storeId, cartId) {
  let response = await wrappedFetch(`${api}/store/${storeId}/receipt/${cartId}`, {
    method: "GET",
  })
  return response.json()
}

/**
 * Gets the RefundRequests by cartId and storeId
 */
export async function getRefundRequests(storeId, cartId) {
  let response = await wrappedFetch(`${api}/store/${storeId}/refund_requests/${cartId}`, {
    method: "GET",
  })
  return response.json()
}

/**
 * Create RefundRequest by passing required fields
 */
export async function createRefundRequest(values) {
  let response = await wrappedFetch(`${api}/refund_request/create`, {
    method: "POST",
    body: JSON.stringify({ values: values }),
  })
  return response.json()
}

/**
 * update RefundRequest by passing required fields
 */
export async function updateRefundRequest(values) {
  let response = await wrappedFetch(`${api}/refund_request/update`, {
    method: "PUT",
    body: JSON.stringify({ values: values }),
  })
  return response.json()
}

/**
 * Gets OperatorReasons master data
 */
export async function getOperatorReasons(storeId, cartId) {
  let response = await wrappedFetch(`${api}/${storeId}/operator_reasons/${cartId}`, {
    method: "GET",
  })
  return response.json()
}

/**
 * Marks a cart as under review
 */
export async function markUnderReview(storeId, cartId) {
  let response = await wrappedFetch(`${api}/${storeId}/mark_under_review/${cartId}`, {
    method: "GET",
  })
  return response.json()
}

/**
 * Initiate refund and mark cart as reviewed
 */
export async function submitRefund(values) {
  let response = await wrappedFetch(`${api}/submit_refund`, {
    method: "POST",
    body: JSON.stringify({ values: values }),
  })
  return response.json()
}

/**
 * Gets the store currency.
 */
export const getStoreCurrency = async (storeId) => {
  let apiEndPoint = `${zippinApi}/stores/${storeId}/currency`
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  if (resp.status !== 200) {
    return null
  }
  return resp.json()
}

/**
 * Gets the order detail by providing cartId and storeId
 */
export function getMTOrder(cartId, storeId) {
  return wrappedFetch(`${api}/stores/${storeId}/orders/${cartId}`, {
    method: "GET",
    headers: getHeaders(),
  }).then((resp) => resp.json())
}

/**
 * update Carts by passing required fields
 */
export async function setCart(storeId, orderId, values) {
  let response = await wrappedFetch(`${api}/stores/${storeId}/orders/${orderId}`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify({ values: values }),
  })
  if (!response.ok) {
    return { error: true, message: response.statusText }
  }
  return response.json()
}

/**
 * Gets the dispute detail by providing cartId and storeId
 */
export function getDisputes(cartId, storeId) {
  return wrappedFetch(`${zippinApi}/stores/${storeId}/carts/${cartId}/disputes`, {
    method: "GET",
    headers: getHeaders(),
  }).then((resp) => resp.json())
}

/**
 * create dispute from orders dashboard by passing required params
 */
export const createDispute = async (storeId, cartId, data) => {
  let apiEndPoint = `${zippinApi}/stores/${storeId}/carts/${cartId}/dispute`
  console.log("createDispute apiEndPoint = " + apiEndPoint)
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
  return handleApiResponse(resp)
}

/**
 * Process dispute by approving or rejecting it
 */
export function updateDisputeRequest(storeId, cartId, data) {
  return wrappedFetch(`${zippinApi}/stores/${storeId}/carts/${cartId}/dispute`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).then((resp) => resp.json())
}

/**
 * Get all reports for the given storeId
 */
export const getAllReports = async (
  storeId,
  startIndex,
  limit,
  sortBy,
  sortDirection,
  startDate,
  endDate
) => {
  let apiEndPoint = `${zippinApi}/stores/${storeId}/reports?limit=${limit}&offset=${startIndex}&sortBy=${sortBy}&sortDirection=${sortDirection}&startDate=${startDate}&endDate=${endDate}`
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  return resp.json()
}

/**
 * Get the report downloadable link for the given storeId and report
 */
export const getDownloadableReportLink = async (storeId, reportName) => {
  let apiEndPoint = `${zippinApi}/stores/${storeId}/reports/${reportName}`
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  return resp.json()
}

export function submitOrder(storeId, order, items, correctedCart, cartUpdatedAt, disputes) {
  let values = {}
  values.customerId = order.customer_id
  let orderId = order.order_id
  values.orderId = orderId
  if (order.start) values.entryTimestamp = Number(order.start)
  if (order.end) values.exitTimestamp = Number(order.end)
  values.items = items
  values.storeId = storeId
  values.correctedCart = correctedCart
  values.cartUpdatedAt = cartUpdatedAt
  values.disputes = disputes

  let apiEndPoint = api + "/pay_order"
  return wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify(values),
  }).then((resp) => resp.json())
}

/**
 * Get the current person occupancy value for a store
 * @param {string} storeId The id for the store to retrieve the occupancy
 */
export const getStoreOccupancy = async (storeId) =>
  wrappedFetch(`${zippinApi}/stores/${storeId}/occupancy`, {
    method: "GET",
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.json())
    }

    return response.json()
  })

/**
 * Set the person occupancy value for a store
 * @param {string} storeId The id for the store to set the occupancy
 * @param {number} occupancy The new occupancy value to set
 */
export const setStoreOccupancy = async (storeId, occupancy) =>
  wrappedFetch(`${zippinApi}/stores/${storeId}/occupancy`, {
    method: "PUT",
    body: JSON.stringify({
      occupancy,
    }),
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.json())
    }

    return response.json()
  })

/**
 * Call API to set a lane's weight noise threshold for a store
 * @param {string} storeId The id for the desired store
 * @param {string} laneId The id for the lane in the store
 * @param {number} noiseThreshold The new weight noise threshold value to set for the lane
 */
export const setLaneNoiseThreshold = async (storeId, laneId, noiseThreshold) => {
  const response = await wrappedFetch(`${operator_api}/stores/${storeId}/lanes/${laneId}/noise`, {
    method: "PUT",
    body: JSON.stringify({
      noiseThreshold,
    }),
  })

  if (!response.ok) {
    let responseBody = await response.json()

    throw new Error(responseBody.data.message)
  }

  return response.json()
}

/**
 * Call API to set a shelf's weight noise threshold for a store
 * @param {string} storeId The id for the desired store
 * @param {string} shelfId The id for the shelf in the store
 * @param {number} noiseThreshold The new weight noise threshold value to set for the shelf
 */
export const setShelfNoiseThreshold = async (storeId, shelfId, noiseThreshold) => {
  const response = await wrappedFetch(
    `${operator_api}/stores/${storeId}/shelves/${shelfId}/noise`,
    {
      method: "PUT",
      body: JSON.stringify({
        noiseThreshold,
      }),
    }
  )

  if (!response.ok) {
    let responseBody = await response.json()

    throw new Error(responseBody.data.message)
  }

  return response.json()
}

/**
 * Create a new correction cart
 * @param storeId {string} The store that the new cart belongs to
 * @param cartId {string} The id of the cart being corrected
 */
export const createCorrectionCart = async (storeId, cartId) => {
  try {
    const apiResponse = await wrappedFetch(`${OPERATOR_API_URL}/stores/${storeId}/carts`, {
      method: "POST",
      body: JSON.stringify({
        cartId,
      }),
    })

    if (!apiResponse.ok) {
      throw new Error(apiResponse.json())
    }
    return apiResponse.json()
  } catch (error) {
    console.error(error)
  }
}

/**
 * Create a new correction cart
 * @param storeId {string} The store that the new cart belongs to
 * @param start {number} The start timestamp for the new cart
 * @param end {number} The end timestamp for the new cart
 * @param userId {string} (optional) The userId for the new cart
 */
export const createCart = async (storeId, start, end, userId) => {
  try {
    const apiResponse = await wrappedFetch(`${OPERATOR_API_URL}/stores/${storeId}/carts`, {
      method: "POST",
      body: JSON.stringify({
        start,
        end,
        userId,
      }),
    })

    if (!apiResponse.ok) {
      throw new Error(apiResponse.json())
    }
    return apiResponse.json()
  } catch (error) {
    console.error(error)
  }
}
/**
 * Create an event on Submit-Cart
 * @param {string} storeId The store that the cart belongs to
 * @param {string} orderId The id of the cart being sent to create createSubmitCartEvent
 */
export const createSubmitCartEvent = async (storeId, orderId, values) => {
  const apiEndPoint = `${OPERATOR_API_URL}/stores/${storeId}/carts/${orderId}`
  const response = await wrappedFetch(`${apiEndPoint}`, {
    method: "PUT",
    body: JSON.stringify({
      values,
    }),
  })
  return response
}
/**
 * Saves client error details for alerting & debugging
 * @param {string} errorDetails stringified error details
 */
export const reportError = async (errorDetails) => {
  console.log("reporting error")
  try {
    const resp = await wrappedFetch(`${zippinApi}/app/dashboard/error`, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        error: errorDetails,
        userAgent: navigator.userAgent,
        language: navigator.language,
        url: window.location.href,
      }),
    })
    return handleApiResponse(resp)
  } catch (e) {
    console.log(e)
  }
}

/**
 * Get the tenant backend store config values for the given config keys
 * @param {string} storeId
 * @param {Array<string>} configKeys config keys to get values for
 * @returns The tenant backend store config values
 */
export async function getTenantBackendStoreConfig(storeId, configKeys) {
  let response = await wrappedFetch(`${TENANT_BACKEND_API_BASE_URL}/stores/${storeId}/config`, {
    method: "POST",
    body: JSON.stringify({
      keys: configKeys,
    }),
  })
  return response.json()
}

export const getActiveUsers = async () => {
  try {
    return await wrappedFetch(`${taskAssignerApi}/active-users`, {
      method: "GET",
    }).then((response) => response.json())
  } catch (e) {
    console.error("getActiveUsers error: ", e)
  }
}

export const getErrorTasks = async () => {
  try {
    return await wrappedFetch(`${taskResultIngestorApi}/error-tasks`, {
      method: "GET",
    }).then((response) => response.json())
  } catch (e) {
    console.error("getErrorTasks error: ", e)
  }
}

export function moveErrorTasksToSubmitQueue(store) {
  try {
    let apiEndPoint = taskResultIngestorApi + `/stores/${store}/handle_queued_error_tasks`
    return wrappedFetch(`${apiEndPoint}`, {
      method: "POST",
    }).then((resp) => {
      return resp.json()
    })
  } catch (e) {
    console.error("moveErrorTasksToSubmitQueue error: ", e)
  }
}

export const fetchAssignmentRules = async () => {
  try {
    return await wrappedFetch(`${taskAssignmentRuleApi}/assignment-rules`, {
      method: "GET",
    }).then((response) => response.json())
  } catch (e) {
    console.error("fetchAssignmentRules error: ", e)
  }
}

export const changeAssignmentRuleStatus = async (ruleName, status) => {
  try {
    const values = {}
    values.status = status
    return await wrappedFetch(`${taskAssignmentRuleApi}/assignment-rules/${ruleName}/status`, {
      method: "POST",
      body: JSON.stringify(values),
    }).then((response) => response.json())
  } catch (e) {
    console.error("changeAssignmentRuleStatus error: ", e)
  }
}

export const createAssignmentRule = async (
  ruleName,
  rule,
  operators,
  status,
  newExclusiveRuleStatus,
  operatorRule
) => {
  try {
    const values = {}
    values.ruleName = ruleName
    values.rule = rule
    values.operators = operators
    values.status = status
    values.exclusiveRule = newExclusiveRuleStatus
    values.operatorRule = operatorRule
    console.log("Submitting: " + JSON.stringify(values))
    return await wrappedFetch(`${taskAssignmentRuleApi}/assignment-rules`, {
      method: "POST",
      body: JSON.stringify(values),
    }).then((response) => response.json())
  } catch (e) {
    console.error("createAssignmentRule error: ", e)
  }
}

export const deleteAssignmentRule = async (ruleName) => {
  try {
    return await wrappedFetch(`${taskAssignmentRuleApi}/assignment-rules/${ruleName}`, {
      method: "Delete",
    }).then((response) => response.json())
  } catch (e) {
    console.error("deleteAssignmentRule error: ", e)
  }
}

export const addOperators = async (ruleName, operators) => {
  try {
    const values = {}
    values.operators = operators
    return await wrappedFetch(`${taskAssignmentRuleApi}/assignment-rules/${ruleName}/operators`, {
      method: "POST",
      body: JSON.stringify(values),
    }).then((response) => response.json())
  } catch (e) {
    console.error("addOperator error: ", e)
  }
}

export const deleteOperators = async (ruleName, operators) => {
  try {
    const values = {}
    values.operators = operators
    return await wrappedFetch(`${taskAssignmentRuleApi}/assignment-rules/${ruleName}/operators`, {
      method: "Delete",
      body: JSON.stringify(values),
    }).then((response) => response.json())
  } catch (e) {
    console.error("deleteOperator error: ", e)
  }
}

export const addRule = async (ruleName, rule) => {
  try {
    const values = {}
    values.rule = rule
    return await wrappedFetch(`${taskAssignmentRuleApi}/assignment-rules/${ruleName}/rule`, {
      method: "POST",
      body: JSON.stringify(values),
    }).then((response) => response.json())
  } catch (e) {
    console.error("addRule error: ", e)
  }
}

export const deleteRule = async (ruleName) => {
  try {
    return await wrappedFetch(`${taskAssignmentRuleApi}/assignment-rules/${ruleName}/rule`, {
      method: "Delete",
    }).then((response) => response.json())
  } catch (e) {
    console.error("deleteRule error: ", e)
  }
}

export const updateExlusiveRuleStatus = async (ruleName, newExclusiveRuleStatus) => {
  try {
    const values = {}
    values.exclusiveRule = newExclusiveRuleStatus
    return await wrappedFetch(
      `${taskAssignmentRuleApi}/assignment-rules/${ruleName}/exclusive-rule`,
      {
        method: "POST",
        body: JSON.stringify(values),
      }
    ).then((response) => response.json())
  } catch (e) {
    console.error("updateExlusiveRuleStatus error: ", e)
  }
}

// Add UserAccess
export const addUserAccess = async (data) => {
  const apiEndPoint = `${Z_API_URL}/useraccess`
  console.log("addUser apiEndPoint = " + apiEndPoint)
  const resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify(data),
  })
  return handleApiResponse(resp)
}

// Update UserAccess
export const updateUserAccess = async (data) => {
  const apiEndPoint = `${Z_API_URL}/useraccess`
  console.log("updateUser apiEndPoint = " + apiEndPoint)
  const resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "PUT",
    body: JSON.stringify(data),
  })
  return handleApiResponse(resp)
}

// get All Users
export const getUserAccess = async (query, limit, page) => {
  let offset = null
  if (page != null) {
    offset = page * limit
  }
  const apiEndPoint = `${Z_API_URL}/useraccess?search=${query}&limit=${limit}&offset=${offset}`
  console.log("getUsers apiEndPoint = " + apiEndPoint)
  const resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  return handleApiResponse(resp)
}

// Delete UserAccess
export const deleteUserAccess = async (data) => {
  const apiEndPoint = `${Z_API_URL}/useraccess/deleteUsers`
  console.log("deleteUser apiEndPoint = " + apiEndPoint)
  const resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify(data),
  })
  return handleApiResponse(resp)
}

//getAll Logs
export const getLogs = async (limit, page, startTime, endTime, user, actionUser, type, storeId) => {
  let offset = null
  if (page != null) {
    offset = page * limit
  }
  let startingTime = null
  let endingTime = null
  if (startTime !== null && endTime !== null) {
    const endTimeUpdated = +endTime + epochEndtime
    startingTime = Math.floor(startTime / 1000)
    endingTime = Math.floor(endTimeUpdated / 1000)
  }
  const apiEndPoint = `${Z_API_URL}/useraccess/getlogs?limit=${limit}&offset=${offset}&startTime=${startingTime}&endTime=${endingTime}&user=${user}&actionUser=${actionUser}&type=${type}&storeId=${storeId}`
  console.log("getLogs apiEndPoint = " + apiEndPoint)
  const resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  return handleApiResponse(resp)
}

//get Store Name and Store Id
export const getStoreDetails = async (stores) => {
  const apiEndPoint = `${Z_API_URL}/useraccess/getStoreName`
  console.log("stores apiEndPoint = " + apiEndPoint)
  const resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: JSON.stringify(stores),
  })
  return handleApiResponse(resp)
}

/**
 * Get the accesstoken for the given authToken
 * @param {string} authToken
 * @returns The accesstoken for shift4
 */
export async function getShift4AccessToken(authToken) {
  let response = await wrappedFetch(
    `${TENANT_BACKEND_API_BASE_URL}/shift4/accesstoken/${authToken}`,
    {
      method: "GET",
    }
  )
  const responseJSON = await response.json()
  const result = { status: response.status, response: responseJSON }
  return result
}

export async function getEntryCamera(storeId, entryId) {
  const response = await wrappedFetch(`${OPERATOR_API_URL}/stores/${storeId}/entries/${entryId}`, {
    method: "GET",
  })
  if (response.status !== 200) return null
  return response.json()
}

export const getTaskTypes = async () => {
  const response = await wrappedFetch(`${taskAssignerApi}/task-types`, {
    method: "GET",
  })
  return response.json()
}

export const getTaskStatuses = async () => {
  const response = await wrappedFetch(`${taskAssignerApi}/task-statuses`, {
    method: "GET",
  })
  return response.json()
}

export const getCrewUsersForStores = async (storeIds) => {
  let apiEndPoint = `${zippinApi}/crew-users?storeIds=${storeIds.join(",")}`
  console.log("getCrewUsersForStores apiEndPoint = " + apiEndPoint)
  let resp = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })

  return resp.json()
}

export function crewAppSupportPushNotification(storeId, message, supportType, storeIds) {
  const values = {
    type: supportType,
    message: message,
  }
  return wrappedFetch(
    `${zippinApi}/stores/${storeId}/notifications?storeIds=${storeIds.join(",")}`,
    {
      method: "POST",
      body: JSON.stringify(values),
    }
  ).then((resp) => resp.json())
}

export async function getConfigKeyValue(storeId, configKey) {
  try {
    const response = await wrappedFetch(
      `${storeConfigApi}/store-config/stores/${storeId}/configs?sKey=${configKey}`,
      {
        method: "GET",
      }
    )
    return response.json()
  } catch (err) {
    throw new Error(err)
  }
}

export async function getSecrets({ skipAuthorization = false } = {}) {
  if (!gCachedSecrets) {
    console.log("getSecrets:: gCachedSecrets is empty")
    let resultJson = null
    const isLocalEnv = process.env.REACT_APP_API_ENV === "development"
    if (isLocalEnv) {
      const secretKey = env.getReactAppTurnServerKey(),
        host = env.getReactAppTurnServerHostnames(),
        iceUrl = env.getReactAppIceServerURL(),
        iceUsername = env.getReactAppIceServerCredential(),
        iceCredential = env.getReactAppIceServerUsername()
      console.log("getSecrets:: Fetched from process.env - mt-dashboard-ui")
      // Format resultJson to match the response from /secrets (mt-dashboard-server/src/getSecrets.ts getSecrets())
      // Please change both to stay in sync
      resultJson = {
        REACT_APP_TURN_SERVER_KEY: [secretKey],
        REACT_APP_TURN_SERVER_HOSTNAMES: [host],
        REACT_APP_ICE_SERVER_URL: [iceUrl],
        REACT_APP_ICE_SERVER_CREDENTIAL: [iceUsername],
        REACT_APP_ICE_SERVER_USERNAME: [iceCredential],
      }
    } else {
      console.log("getSecrets:: Fetching from process.env - mt-dashboard-server")
      let apiEndPoint = `${api}/secrets?${new URLSearchParams({ skipAuthorization })}`
      let response = await wrappedFetch(apiEndPoint, {
        method: "GET",
      })
      resultJson = await response.json()
    }
    gCachedSecrets = resultJson
  } else {
    console.log("getSecrets:: gCachedSecrets is retrieved")
  }
  return gCachedSecrets
}

export const fetchAutoSolveHITLTasksRules = async () => {
  try {
    const response = await wrappedFetch(`${taskResultIngestorApi}/stores/auto-solve-tasks-rules`, {
      method: "GET",
    })
    return response.json()
  } catch (err) {
    console.error(
      "fetchAutoSolveHITLTasksRules: Error while fetching auto solve HITL Tasks rules",
      err
    )
  }
}

export const addAutoSolveHITLTasksRule = async (ruleDetails) => {
  try {
    const ruleId = ruleDetails.ruleName
    const storeId = ruleDetails.storeId
    const rule = { ...ruleDetails, ruleId }
    delete rule.ruleName
    delete rule.storeId
    const response = await wrappedFetch(
      `${taskResultIngestorApi}/stores/${storeId}/auto-solve-tasks-rule`,
      {
        method: "POST",
        body: JSON.stringify(rule),
      }
    )
    return handleApiResponse(response)
  } catch (err) {
    console.error(`Error while adding rule ${JSON.stringify(ruleDetails)}`)
    throw err
  }
}

export const editAutoSolveHITLTasksRule = async (ruleDetails) => {
  try {
    const ruleId = ruleDetails.ruleName
    const storeId = ruleDetails.storeId
    const rule = { ...ruleDetails, ruleId }
    delete rule.ruleName
    delete rule.storeId
    const response = await wrappedFetch(
      `${taskResultIngestorApi}/stores/${storeId}/auto-solve-tasks-rules/${ruleId}`,
      {
        method: "PUT",
        body: JSON.stringify(rule),
      }
    )
    return handleApiResponse(response)
  } catch (err) {
    console.error(`Error while editing the rule ${JSON.stringify(ruleDetails)}`)
    throw err
  }
}

export const deleteAutoSolveHITLTasksRule = async (storeId, ruleId) => {
  try {
    const response = await wrappedFetch(
      `${taskResultIngestorApi}/stores/${storeId}/auto-solve-tasks-rules/${ruleId}`,
      {
        method: "DELETE",
      }
    )
    return handleApiResponse(response)
  } catch (err) {
    console.error(`Error while deleting rule ${ruleId}`)
    throw err
  }
}

export const getAutoSolveTasks = async (rule) => {
  try {
    const storeId = rule.storeId
    const parsedCondition = rule.condition ? JSON.parse(rule.condition) : {}
    let condition = {}
    for (const key in parsedCondition) {
      const value = parsedCondition[key]
      if (value) {
        condition[key] = value
      }
    }
    const URLQueryParamsObject = {
      start_time: rule.start,
      end_time: rule.end,
      task_type: rule.taskType,
      ...(Object.keys(condition).length > 0 && condition),
    }
    const URLQueryParams = new URLSearchParams(URLQueryParamsObject)

    const response = await wrappedFetch(
      `${taskResultIngestorApi}/stores/${storeId}/tasks?${URLQueryParams.toString()}`,
      {
        method: "GET",
      }
    )
    return response.json()
  } catch (err) {
    console.error(`Error while getting tasks for rule ${rule}`)
  }
}

export const getStoreProductsWithShelfIds = async (storeId) => {
  try {
    const apiEndPoint = `${OPERATOR_API_URL}/stores/${storeId}/products-with-shelfIds`
    const response = await wrappedFetch(`${apiEndPoint}`, {
      method: "GET",
    })
    return response.json()
  } catch (err) {
    console.error(
      `getStoreProductsWithShelfIds: Error in getting store products with shelfIds`,
      err
    )
    return null
  }
}

export const fetchActiveProductsOfStoreBetweenTimestamps = async (storeId, start, end) => {
  try {
    const apiEndPoint = `${OPERATOR_API_URL}/stores/${storeId}/active-products?start=${start}&end=${end}`
    const response = await wrappedFetch(`${apiEndPoint}`, {
      method: "GET",
    })
    return response.json()
  } catch (err) {
    console.error(
      `fetchActiveProductsOfStoreBetweenTimestampss: Error in fetching active products of store between ${start} and ${end} timestamps`,
      err
    )
    return null
  }
}

export const getStoreTimezone = async (stores) => {
  const apiEndPoint = `${Z_API_URL}/store-timezone?stores=${stores}`
  const response = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  const responseJson = await response.json()
  return [responseJson, response.status]
}
export const getTaxTypes = async (tenantId) => {
  const apiEndPoint = `${zippinApi}/tenant/${tenantId}/tax-types`
  const response = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  const responseJson = await response.json()
  return responseJson
}
export const addTaxTypes = async (tenantId, code, name, taxType, storeId, userEmail) => {
  const apiEndPoint = `${zippinApi}/tenant/${tenantId}/tax-types`
  const data = {
    name,
    taxType,
    code,
    email: userEmail,
    storeId,
  }
  const resp = await wrappedFetch(apiEndPoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
  const finalResult = await handleApiResponse(resp)
  if (finalResult.error) {
    throw new Error(finalResult.message || "Failed to Add tax type")
  }
  return finalResult
}
export const updateTaxTypes = async (tenantId, name, taxType, code, storeId, userEmail) => {
  const apiEndPoint = `${zippinApi}/tenant/${tenantId}/tax-types`
  const data = {
    name,
    taxType,
    code,
    email: userEmail,
    storeId,
  }

  const resp = await wrappedFetch(apiEndPoint, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
  const finalResult = await handleApiResponse(resp)
  if (finalResult.error) {
    throw new Error(finalResult.message || "Failed to update tax type")
  }
  return finalResult
}
export const getTaxesBySkuId = async (tenantId, skuId, limit, offset) => {
  const apiEndPoint = `${zippinApi}/tenant/${tenantId}/tax-types/${skuId}/taxes?limit=${limit}&offset=${offset}`
  const response = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  const responseJson = await response.json()
  const responseData = { data: responseJson.sku, totalCount: responseJson.totalCount }
  return responseData
}
export const getPricesBySkuId = async (tenantId, skuId, limit, offset) => {
  const apiEndPoint = `${zippinApi}/tenant/${tenantId}/skus/${skuId}/prices?limit=${limit}&offset=${offset}`
  const response = await wrappedFetch(`${apiEndPoint}`, {
    method: "GET",
  })
  const responseJson = await response.json()
  const responseData = { data: responseJson.sku, totalCount: responseJson.totalCount }
  return responseData
}

export const getCartDetail = async (cartId, storeId) => {
  try {
    const apiEndPoint = `${TENANT_BACKEND_API_BASE_URL}/stores/${storeId}/carts/${cartId}`
    const response = await wrappedFetch(`${apiEndPoint}`, {
      method: "GET",
    })
    let responseJson = await response.json()
    return [response.status, responseJson]
  } catch (err) {
    console.error(`getCartDetail: Error fetching cart details`, err)
    return null
  }
}

export const cartSubmissionRetry = async (orderInfo) => {
  const apiEndPoint = `${zippinApi}/cart`
  const response = await wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
    body: orderInfo,
  })
  return handleApiResponse(response)
}

export const cartPaymentRetry = async (orderId) => {
  const apiEndPoint = `${TENANT_BACKEND_API_BASE_URL}/cart/${orderId}/retry`
  const response = await wrappedFetch(`${apiEndPoint}`, {
    method: "POST",
  })
  const result = handleApiResponse(response)
  return result
}

export const sendUIMetricsToStoreConfigAPI = async (measurement, labels, values, clientName) => {
  try {
    const metrics = {
      dataPoints: [
        {
          metric: measurement,
          labels: labels,
          values: values,
          timestampEpochMs: Date.now(),
        },
      ],
      clientName: clientName,
    }
    const response = await wrappedFetch(`${storeConfigApi}/metrics/${UI_METRICS_DATABASE}`, {
      method: "POST",
      body: JSON.stringify(metrics),
    })
    return response
  } catch (err) {
    console.error(`UI Metrics: Error sending metrics to store config API`, err)
    return null
  }
}
